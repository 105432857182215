
import { Component } from 'vue-property-decorator';
import LoginForm from '../components/auth/LoginForm.vue';
import { namespace } from 'vuex-class';
import { Credentials } from '@/interfaces/auth/Credentials';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';

const auth = namespace('auth');
const app = namespace('app');

@Component({
  components: { LoginForm },
})
export default class Start extends mixins(Notification) {
  @auth.Action('login') public doLogin!: any;
  @app.State('venues') public venues!: Venue[];

  public login(data: Credentials) {
    this.doLogin(data).then(() => {
      this.$router.push({ name: 'venue.index' });
      this.notifySuccess('auth.notification.login');
    });
  }
}
